import React from 'react';
// import Layout from '../components/layout';
import { Link } from 'gatsby';

function notFound() {
  return (
    <div>404</div>
  );
};

export default notFound;

/* <Layout title={`Page Not Found`}>
      <h1>The page doesn't exist :(</h1>
      <Link to='/'>Back to home</Link>
    </Layout> */